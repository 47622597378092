import React, { useEffect, useState } from "react";
import {
  DivOrder,
  TitleOrder,
  OrderForm,
  InputBig,
  Label,
  LabelInfo,
  BtnForm,
  DivOrg,
  DivTotal,
  DivOrgResults,
  InfoResult,
  DivOrgScreen,
  InputSmall,
  SelectDoc,
  Options,
  DivAlerts,
  TitleAlert,
  Alerts,
  SelectCodProduct,
  SelectClient,
  BtnAddClient,
  DivOrgBtn,
  BtnReloading,
  BtnAddSon,
} from "./OrderStyle";
import DetailOrder from "../../Details/DetailOrder/DetailOrder";

import { ClipLoader } from "react-spinners";
import { useForm } from "react-hook-form";
import { getProduct } from "../../../store/registers/products/products.actions";
import { getClient } from "../../../store/registers/clients/clients.actions";
import { useDispatch } from "react-redux";
import { useDebounce } from "use-debounce";
import { NumericFormat } from "react-number-format";
import { ChildCare, Loop, PersonAdd } from "@styled-icons/material";
import NewClientPopUp from "../NewClientPopUp/NewClientPopUp";
import NewSonPopUp from "../NewSonPopUp/NewSonPopUp";

export default function Order(props) {
  const dispatch = useDispatch();

  // items para alterações
  const infoOrderChange = props.infoOrderChange;
  const infoClientChange = props.infoOrderChange;
  const listProducts = props.listProduct;
  const listClientsInfo = props.listClient;

  // Cart states guide
  const [cartItem, setCartItem] = useState([]);
  const [infoClient, setInfoClient] = useState([]);
  // Product States guide
  const [productId, setProductId] = useState(0);
  const [productsInfo, setProductsInfo] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [productSearch] = useDebounce(productId, 300);
  const [valueDiscount, setValueDiscount] = useState(0);
  const [showAlertQtd, setShowAlertQtd] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [qtdSell, setQtdSell] = useState(0);

  const [selectProd, setSelectProd] = useState("nome");
  // const [codProduct, setCodProduct] = useState("");
  const [isClearable] = useState(true);
  // const [product, setProduct] = useState([]);
  const [optionsProducts, setOptionsProducts] = useState([]);

  const [showAlertZero, setShowAlertZero] = useState(false);

  // Client State guide
  const [clientId, setClientId] = useState("");
  const [clientsInfo, setClientsInfo] = useState([]);
  const [optionsClients, setOptionsClients] = useState([]);
  const [clientSearch] = useDebounce(clientId, 300);
  const [showPopClient, setShowPopClient] = useState(false);
  const [showPopSon, setShowPopSon] = useState(false);

  const { register, handleSubmit, watch, setValue, reset, formState } = useForm(
    {
      defaultValues: {
        priceWithDiscount: 0,
        priceNoDiscount: 0,
        valueDiscount: 0,
        qtd: 0,
      },
    }
  );

  const getIdProduct = async (idProduct) => {
    setLoadingProduct(true);
    const valueProduct = idProduct;
    const productId = await dispatch(getProduct(valueProduct));
    setProductsInfo(productId.payload);
    setLoadingProduct(false);
  };

  const getIdClient = async (idClient) => {
    const valueClient = idClient.cpf;
    const client = await dispatch(getClient(valueClient));

    setClientsInfo(client.payload);
  };

  function parseName(oneName, secondName) {
    const firstName = oneName || " ";
    const lastName = secondName || " ";
    var fullName = firstName.concat(" ", lastName) || "";
    const formatName = fullName.split(" ");
    for (var i = 0; i < formatName.length; i++) {
      formatName[i] =
        formatName[i].charAt(0).toUpperCase() + formatName[i].slice(1);
    }
    const result = formatName.join(" ");

    return result;
  }

  function sendProductToCheckout(dataProduct) {
    // preço unitarios
    dataProduct.priceUnit = parseFloat(priceUnit);
    dataProduct.valueDiscount = discount;
    dataProduct.nameProduct = nameProduct;
    dataProduct.codProd = productSearch;

    if (dataProduct.codProd === undefined) {
      console.log("Produto não encontrado");
    } else {
      if (clientsInfo?.client?.clientName === undefined) {
        setInfoClient({
          nameClient: "Cliente Não identificado",
        });
      } else {
        const clientNameFormat = parseName(
          clientsInfo?.client?.clientName,
          clientsInfo?.client?.lastName
        );
        setInfoClient({
          nameClient: clientNameFormat,
          cpf: clientsInfo.client.cpf,
          idClient: clientsInfo.client.idClient,
          city: clientsInfo.client.city,
          district: clientsInfo.client.district,
          street: clientsInfo.client.street,
          localNumber: clientsInfo.client.localNumber,
          state: clientsInfo.client.state,
          complement: clientsInfo.client.complement,
        });
      }
      setCartItem(dataProduct);
    }
  }
  const disableScroll = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  };

  const sendProduct = (product) => {
    if (product != null) {
      setProductId(product.codProd);
    } else {
      setProductsInfo(productSearch);
    }
  };

  let qtd = watch("qtd");
  // priceunit vai receber o valor do produto ao buscar no banco product.value
  let priceUnit = productsInfo?.product?.priceSell.toFixed(2);
  let nameProduct = productsInfo?.product?.nameProduct;
  let discount = Number(valueDiscount);

  useEffect(() => {
    let totalPrice;
    if (discount > 0) {
      const priceNoDiscount = qtd * priceUnit;
      const valueDiscount = parseFloat(
        (qtd * priceUnit * discount) / 100
      ).toFixed(2);
      totalPrice = qtd * priceUnit - discount;
      setValue("priceWithDiscount", Number(totalPrice.toFixed(2)));
      setValue("priceNoDiscount", Number(priceNoDiscount.toFixed(2)));
      setValue("valueDiscount", Number(valueDiscount));
    } else {
      totalPrice = qtd * priceUnit;
      setValue("valueDiscount", 0);
      setValue("priceWithDiscount", Number(totalPrice.toFixed(2)));
      setValue("priceNoDiscount", Number(totalPrice.toFixed(2)));
    }
    // setValue("totalValue", totalPrice);
  }, [qtd, discount, setValue, priceUnit]);

  useEffect(() => {
    if (productSearch?.length === 0) {
      setProductsInfo(productSearch);
    }
    if (productSearch) {
      getIdProduct(productSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productSearch]);

  useEffect(() => {
    if (clientSearch?.length === 0) {
      setClientsInfo(clientSearch);
    }
    if (clientSearch) {
      getIdClient(clientSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSearch]);

  useEffect(() => {
    if (infoClientChange?.detailOrder?.infoClient?.nameClient.length > 0) {
      setClientId(infoClientChange?.detailOrder.infoClient.cpf);
      setClientsInfo([]);
      setInfoClient(infoClientChange?.detailOrder?.infoClient);
    }
  }, [infoClientChange]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(() => {
        reset();
        setProductsInfo([]);
        setValueDiscount("");
      }, 1500);
    }
  }, [formState, reset, setValue]);

  useEffect(() => {
    if (qtdSell > productsInfo?.product?.qtdNow) {
      setShowAlertQtd(true);
      setDisableBtn(true);
    } else {
      setShowAlertQtd(false);
      setDisableBtn(false);
    }
  }, [productsInfo, qtdSell]);

  useEffect(() => {
    if (productsInfo?.product?.qtdNow === 0) {
      setShowAlertZero(true);
      setDisableBtn(true);
    } else {
      setShowAlertZero(false);
      setDisableBtn(false);
    }
  }, [productsInfo]);

  useEffect(() => {
    if (listProducts?.length > 0) {
      const optionsProducts = listProducts.map((product) => ({
        value: product.idProduct,
        label: product.nameProduct + " / " + product.nameBrand,
        codProd: product.codProd,
      }));
      setOptionsProducts(optionsProducts);
    }
    if (listClientsInfo?.length > 0) {
      const optionsClients = listClientsInfo.map((client) => ({
        value: client.idClient,
        label:
          client.clientName + " " + client.lastName + " / " + client.docClient,
        cpf: client.docClient,
      }));
      setOptionsClients(optionsClients);
    }
  }, [listProducts, listClientsInfo]);

  useEffect(() => {
    if (selectProd === "nome") {
      setProductId("");
    }
  }, [selectProd]);

  return (
    <DivOrgScreen>
      <DivOrder>
        <TitleOrder>Adicionar Produto</TitleOrder>
        <OrderForm
          autoComplete="off"
          onSubmit={handleSubmit(sendProductToCheckout)}
        >
          <DivOrg>
            {/* <Label>Nome Cliente</Label> */}
            <SelectClient
              name="nameClient"
              placeholder="Buscar nome do Cliente"
              options={optionsClients}
              isClearable={isClearable}
              onChange={setClientId}
            />
            <DivOrgBtn>
              <BtnAddClient
                type="button"
                onClick={() => setShowPopClient(!showPopClient)}
              >
                <PersonAdd />
              </BtnAddClient>
              <BtnAddSon
                type="button"
                onClick={() => setShowPopSon(!showPopSon)}
              >
                <ChildCare />
              </BtnAddSon>
              <BtnReloading
                type="button"
                onClick={() => props.setReloadingClient(true)}
              >
                <Loop />
              </BtnReloading>
            </DivOrgBtn>
          </DivOrg>
          <DivOrg>
            <Label>
              <SelectDoc onChange={(e) => setSelectProd(e.target.value)}>
                <Options value="nome">Nome</Options>
                <Options value="codigo">Codigo</Options>
              </SelectDoc>
              Produto
            </Label>
            {selectProd === "nome" ? (
              <SelectCodProduct
                name="product"
                placeholder="Buscar o nome"
                options={optionsProducts}
                isClearable={isClearable}
                onChange={sendProduct}
              />
            ) : (
              <InputBig
                onFocus={disableScroll}
                type="number"
                placeholder="Codigo"
                {...register("codProd")}
                onChange={(e) => setProductId(e.target.value)}
              />
            )}
          </DivOrg>
          {productsInfo.errorStatus && (
            <DivOrgResults>
              <InfoResult>{productsInfo.message}</InfoResult>
            </DivOrgResults>
          )}

          <DivOrg>
            <Label>Nome Produto</Label>
            <LabelInfo>
              {loadingProduct ? (
                <ClipLoader speedMultiplier={3} />
              ) : (
                productsInfo?.product?.nameProduct || "-"
              )}
            </LabelInfo>
          </DivOrg>
          <DivOrg>
            <Label>Preço</Label>
            <LabelInfo>
              {loadingProduct ? (
                <ClipLoader speedMultiplier={3} />
              ) : (
                <NumericFormat
                  value={productsInfo?.product?.priceSell || 0}
                  placeholder=""
                  customInput={LabelInfo}
                  displayType="text"
                  decimalSeparator=","
                  thousandSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  prefix={"R$ "}
                />
              )}
            </LabelInfo>
          </DivOrg>
          <DivOrg>
            <Label>Quantidade</Label>
            <InputSmall
              onFocus={disableScroll}
              type="number"
              step="any"
              placeholder="Quantidade"
              {...register("qtd", {
                valueAsNumber: true,
                required: true,
                onChange: (e) => {
                  setQtdSell(e.target.value);
                },
              })}
            />
          </DivOrg>
          <DivOrg>
            <Label>Desconto</Label>
            <NumericFormat
              value={valueDiscount}
              customInput={InputSmall}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix={"R$ "}
              onValueChange={(values, sourceInfo) => {
                setValueDiscount(values.value);
              }}
            />
          </DivOrg>
          {showAlertQtd && (
            <DivAlerts>
              <TitleAlert>AVISO!!</TitleAlert>
              <Alerts>
                A quantidade a ser vendida é maior que o estoque atual de{" "}
                {productsInfo?.product?.qtdNow} a venda não pode ser realizada
              </Alerts>
            </DivAlerts>
          )}
          {showAlertZero && (
            <DivAlerts>
              <TitleAlert>AVISO!!</TitleAlert>
              <Alerts>
                Esse item está com quantidade zero no estoque, a venda não pode
                ser realizada
              </Alerts>
            </DivAlerts>
          )}
          <DivTotal>
            <Label>Total</Label>
            <NumericFormat
              placeholder=""
              customInput={LabelInfo}
              displayType="text"
              value={watch("priceWithDiscount") || 0}
              decimalSeparator=","
              thousandSeparator="."
              fixedDecimalScale
              decimalScale={2}
              prefix={"R$ "}
            />
            {/* <LabelInfo>{watch("totalValue") || 0}</LabelInfo> */}
          </DivTotal>
          <BtnForm type="submit" disabled={disableBtn}>
            Adicionar
          </BtnForm>
        </OrderForm>
        <NewClientPopUp
          showPopClient={showPopClient}
          setShowPopClient={setShowPopClient}
        />
        <NewSonPopUp 
          showPopSon={showPopSon}
          setShowPopSon={setShowPopSon}
          listClientsInfo={listClientsInfo}
        />
      </DivOrder>
      <DetailOrder
        cartItem={cartItem}
        infoClient={infoClient}
        infoOrderChange={infoOrderChange}
      />
    </DivOrgScreen>
  );
}
